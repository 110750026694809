import React from "react"
import PropTypes from "prop-types"
import CaseStudiesHorizontalList from "../components/case-studies-horizontal-list"
import { graphql, StaticQuery } from "gatsby"

var findOne = function (caseStudyTags, tags) {
  return caseStudyTags.some(function (tag) {
    return tags.find(current => current.id === tag.id)
  })
}

const RelatedCaseStudies = ({ currentSlug, tags }) => {
  const cleanTags = tags ? tags : []
  return (
    <StaticQuery
      query={graphql`
        query relatedCaseStudiesQuery {
          relatedCaseStudies: allWordpressWpCaseStudies {
            edges {
              node {
                id
                slug
                date
                title
                tags {
                  id
                }
                acf {
                  the_problem
                  the_solution
                  featured_image {
                    sizes {
                      thumbnail
                      medium
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const caseStudies = data.relatedCaseStudies.edges.filter(caseStudy => {
          const caseStudyTags = caseStudy.node.tags ? caseStudy.node.tags : []
          return (
            currentSlug !== caseStudy.node.slug &&
            findOne(caseStudyTags, cleanTags)
          )
        })
        const title = caseStudies.length > 0 ? "Related Case Studies" : ""
        return (
          <CaseStudiesHorizontalList
            title={title}
            caseStudies={caseStudies}
          ></CaseStudiesHorizontalList>
        )
      }}
    />
  )
}

RelatedCaseStudies.propTypes = {
  currentSlug: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
}

export default RelatedCaseStudies
