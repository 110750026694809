import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { graphql } from "gatsby"

import case_problem_icon from "../imgs/case_problem.svg"
import case_result_icon from "../imgs/case_result.svg"
import case_solution_icon from "../imgs/case_solution.svg"
import RelatedCaseStudies from "../components/related-case-studies"

function createMarkup(source) {
  return { __html: source }
}

const CaseStudyTemplate = ({ data, location }) => (
  <Layout location={location}>
    <SEO title={data.caseStudy.title}></SEO>
    {/* Case Studies Single */}
    <section className="case-single">
      <div
        className="case-single__header"
        style={{
          backgroundImage: `url("${data.caseStudy.acf.featured_image.sizes.medium}")`,
        }}
      >
        <div className="container">
          <div className="row row--center">
            <div className="col col__8 col__m10">
              <h1 className="case-single__title">{data.caseStudy.title}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col col__12">
            <div className="case-single__content">
              {/* The Problem */}
              <article className="case-single__item case-single__item--problem">
                <figure className="case-single__image">
                  <img alt="" src={case_problem_icon} />
                </figure>
                <h2 className="case-single__subtitle">The Problem</h2>
                <p className="case-single__desc">
                  {data.caseStudy.acf.the_problem}
                </p>
                <div
                  className="case-single__list case-study-container"
                  dangerouslySetInnerHTML={createMarkup(
                    data.caseStudy.acf.the_problem_sub_description
                  )}
                ></div>
              </article>
              {/* The Solution */}
              <article className="case-single__item case-single__item--solution">
                <figure className="case-single__image">
                  <img alt="" src={case_solution_icon} />
                </figure>
                <h2 className="case-single__subtitle">The Solution</h2>
                <p className="case-single__desc">
                  {data.caseStudy.acf.the_solution}
                </p>
                <div
                  className="case-single__list case-study-container"
                  dangerouslySetInnerHTML={createMarkup(
                    data.caseStudy.acf.the_solution_sub_description
                  )}
                ></div>
              </article>
              {/* The Result */}
              <article className="case-single__item case-single__item--result">
                <figure className="case-single__image">
                  <img alt="" src={case_result_icon} />
                </figure>
                <h2 className="case-single__subtitle">The Result</h2>
                <p className="case-single__desc">
                  {data.caseStudy.acf.the_result}
                </p>
                <div
                  className="case-single__list case-study-container"
                  dangerouslySetInnerHTML={createMarkup(
                    data.caseStudy.acf.the_result_sub_description
                  )}
                ></div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
    <RelatedCaseStudies
      currentSlug={data.caseStudy.slug}
      tags={data.caseStudy.tags ? data.caseStudy.tags : []}
    ></RelatedCaseStudies>
  </Layout>
)

export const query = graphql`
  query($id: String!) {
    caseStudy: wordpressWpCaseStudies(id: { eq: $id }) {
      title
      slug
      tags {
        id
      }
      acf {
        the_problem
        the_problem_sub_description
        the_result
        the_result_sub_description
        the_solution
        the_solution_sub_description
        featured_image {
          sizes {
            thumbnail
            medium
          }
        }
      }
    }
  }
`

export default CaseStudyTemplate
