import React from "react"
import { Link } from "gatsby"
import { caseStudiesPath } from "../utils"
import PropTypes from "prop-types"

const CaseStudiesHorizontalList = ({ title, caseStudies, limit }) => {
  let computedLimit = 3
  if (limit) {
    computedLimit = limit
  }
  const limitedCaseStudies =
    caseStudies.length > computedLimit
      ? caseStudies.splice(0, computedLimit)
      : caseStudies
  return (
    <div>
      {/* Case Studies */}
      <section className="case-list">
        <div className="container">
          <div className="row">
            <div className="col col__12">
              <div className="case-list__header">
                <h1 className="title title">{title}</h1>
              </div>
            </div>
          </div>
          <div className="row">
            {limitedCaseStudies.map(({ node }) => (
              <div key={node.slug} className="col col__4">
                <article className="case-list__item">
                  <Link to={caseStudiesPath(node.slug)}>
                    <figure className="case-list__image">
                      <img
                        src={node.acf.featured_image.sizes.medium}
                        alt="Leading B2B SaaS Healthcare Solutions Provider"
                      />
                    </figure>
                    <div className="case-list__data">
                      <h2 className="case-list__title">{node.title}</h2>
                      <p className="case-list__description">
                        {node.acf.the_solution}
                      </p>
                    </div>
                  </Link>
                </article>
              </div>
            ))}
          </div>
          <div className="row row--center">
            <div className="col col__12">
              <div className="case-list__more">
                <Link
                  to={caseStudiesPath("")}
                  className="button button--secondary"
                >
                  View More Cases
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

CaseStudiesHorizontalList.propTypes = {
  title: PropTypes.string.isRequired,
  caseStudies: PropTypes.array.isRequired,
}

export default CaseStudiesHorizontalList
